@use "../_variables/variables" as *;
@use "effects" as *;
@use "others" as *;
@use "text" as *;

@mixin btn_base {
	font-weight: normal;
	display: block;
	text-align: center;
	text-decoration: none;
	position: relative;
	line-height: 1.4;
	@include transition;
	@include dec-none;
}

@mixin btn_normal($color:$white,$bg:$main_c,$border:$main_c,$color_h:$white,$bg_h:$point_c,$border_h:$point_c){
	@include btn_base;
	color:$color;
	background-color: $bg;
	border:1px solid;
	border-color: $border;
	padding:1rem 4%;
	width: 100%;
	&:hover{
		background-color:$color_h;
		background-color:$bg_h;
		border-color:$border_h;
	}
}

// 矢印あり
@mixin btn_arrow($color:$white,$bg:$main_c,$border:$main_c,$color_h:$white,$bg_h:$hover_c,$border_h:$hover_c){
	@include btn_base;
	color:$color;
	background-color: $bg;
	border:1px solid;
	border-color: $border;
	padding:1rem 0.75em;
	width: 100%;
	span {
		padding: 0 1.25em;
		position: relative;
		width: 100%;
		display: block;
		&::before {
			position: absolute;
			left: calc(100% - 16px);
			top: 50%;
			content: "";
			background: $color;
			@include transition;
			width: 16px;
			height: 1px;
		}
		&::after {
			position: absolute;
			left: 100%;
			bottom: 50%;
			content: "";
			background: $color;
			transform-origin: left bottom;
			@include transition;
			width: 8px;
			height: 1px;
			transform: rotate(225deg);
		}
	}
	&:hover{
		color:$color_h;
		background-color:$bg_h;
		border-color:$border_h;
		& span::before {
			left: calc(100% - 13px);
		}
		& span::after {
			left: calc(100% + 3px);
		}
	}
}

// リンクボタン
@mixin btn_link($color:$white,$bg:$main_c,$border:$main_c,$color_h:$white,$bg_h:$hover_c,$border_h:$hover_c){
	@include btn_base;
	color:$color;
	background-color: $bg;
	border:1px solid;
	border-color: $border;
	padding:1rem 0.75em;
	width: 100%;
	span {
		padding:0 1.25em;
		position: relative;
		width: 100%;
		display: block;
		@include icon(link,after);
		&::after {
			position: absolute;
			right: 0;
			top: calc(50% - 3px);
			@include f-w(400);
			transform: translateY(-50%);
			color: $color;
			@include transition;
			font-size: 1.25em;
		}
	}
	&:hover{
		color:$color_h;
		background-color:$bg_h;
		border-color:$border_h;
	}
}
